import React, { useState, useEffect } from "react";
import API from "../../utils/API";
import Product from "../../components/fields/Product";
import OrderStatus from "../../components/status/OrderStatus";
import ProductMulti from "../../components/formfields/ProductMulti";
import OrderStatusSelect from "../../components/formfields/OrderStatusSelect";
import Order from "../../components/fields/Order";
import SortableTable from "../../components/SortableTable";

const LenderOrders = ({ lenderId }) => {
  const [orders, setOrders] = useState(null);
  const [filtration, setFiltration] = useState({});
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  const columns = [
    {
      name: "ID",
      selector: (row) => (row.lenderorder ? row.lenderorder.id : row.id),
      cell: (row) => <span>{row.lenderorder?.id || row.id}</span>,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) =>
        row.lenderorder
          ? row.lenderorder.title.toLowerCase()
          : row.title.toLowerCase(),
      cell: (row) => <Order order={row?.lenderorder || row} tags={true} />,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row.product.title.toLowerCase(),
      cell: (row) => <Product product={row.product} />,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.lenderorder
          ? row.lenderorder.status.toLowerCase()
          : row.status.toLowerCase(),
      cell: (row) => (
        <OrderStatus status={row.lenderorder?.status || row.status} />
      ),
      sortable: true,
    },
    {
      name: "Return",
      selector: (row) => row.caplenderordercache.returnedoverall && row.caplenderordercache.matchedoverall && row.caplenderordercache.matchedoverall > 0 ? Math.round( parseInt(row.caplenderordercache.returnedoverall) / (parseInt(row.caplenderordercache.matchedoverall) + parseInt(row.caplenderordercache.returnedoverall)) * 10000 ) / 100 : 0,
      cell: (row) => row.caplenderordercache.returnedoverall && row.caplenderordercache.matchedoverall && row.caplenderordercache.matchedoverall > 0 ? Math.round( parseInt(row.caplenderordercache.returnedoverall) / (parseInt(row.caplenderordercache.matchedoverall) + parseInt(row.caplenderordercache.returnedoverall)) * 10000 ) / 100 + '%' : '0%',
      sortable: true,
    },
    {
      name: "Price Per Lead",
      selector: (row) => row.lenderorder.ispingpost ? Number.MAX_SAFE_INTEGER-2 : row.lenderorder.price ? parseFloat(row.lenderorder.price) : Number.MAX_SAFE_INTEGER-1,
      cell: (row) =>  row.lenderorder.ispingpost ? "pp" : row.lenderorder.price ? '$' + parseFloat(row.lenderorder.price) : 'Undefined',
      sortable: true,
    },
    {
      name: "Leads",
      cell: (row) => (
        <span>
          <nobr>
            <span className="small">
              Overall: {row?.caplenderordercache?.matchedoverall}
            </span>
          </nobr>
          <br />
          <nobr>
            <span className="small">
              Today: {row?.caplenderordercache?.matchedtoday}
            </span>
          </nobr>
          <br />
          <nobr>
            <span className="small">
              Current Month: {row?.caplenderordercache?.matchedcurrentmonths}
            </span>
          </nobr>
          <br />
          <nobr>
            <span className="small">
              Start Date: {row?.lenderorder?.datedayfirstmatched}
            </span>
          </nobr>
          <br />
        </span>
      ),
      sortable: false,
    },
    {
      name: "Cap",
      cell: (row) => (
        <span>
          <nobr>
            <br />
            <span className="small">
              Overall: {row.lenderorderfiltercap?.filterdata?.value}
            </span>
          </nobr>
          <br />
          <nobr>
            <span className="small">
              Daily: {row.lenderorderfiltercapday?.filterdata?.value}
            </span>
          </nobr>
          <br />
          <nobr>
            <span className="small">
              Monthly: {row.lenderorderfiltercapmonths?.filterdata?.value}
            </span>
          </nobr>
          <br />
          <br />
        </span>
      ),
      sortable: false,
    },
  ];

  useEffect(() => {
    if (initialLoad) {
      setFiltration({ lender: [lenderId] });
    } else {
      API.get("/admin/order/lender/" + lenderId).then((response) => {
        setOrders(response.data.data);
        setLoading(false);
      });
    }
  }, [lenderId]);

  useEffect(() => {
    if (Object.keys(filtration).length) {
      setLoading(true);
      API.post("/admin/capmanagement/orders", filtration).then((response) => {
        setOrders(response.data.data);
        setLoading(false);
        if (initialLoad) setInitialLoad(false);
      });
    }
  }, [filtration, lenderId]);

  const handleFilterChange = (name, value) => {
    setFiltration((prevState) => ({
      ...prevState,
      [name]: value,
      lender: [lenderId],
    }));
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col-4">
          <ProductMulti
            name={"product"}
            onChange={(name, value) => handleFilterChange(name, value)}
          />
        </div>
        <div className="col-4">
          <OrderStatusSelect
            name={"status"}
            onChange={(name, value) => handleFilterChange(name, value)}
          />
        </div>
        <div className="col-4">
          <input
            type="text"
            className="form-control"
            placeholder="Keyword"
            onChange={(e) => handleFilterChange("keyword", e.target.value)}
          />
        </div>
      </div>

      {orders && orders.length > 0 && (
        <SortableTable columns={columns} data={orders} pagination={false} />
      )}
    </>
  );
};

export default LenderOrders;
