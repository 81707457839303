import React, {useEffect, useState} from "react";
import {
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvField,
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import {toastr} from "react-redux-toastr";
import {Folder} from "react-feather";

const Role = (props) => {
    const [role, setRole] = useState({title:null,permissions:[]});
    const [permissionTree, setPermissionTree] = useState(null);
    const [error, setError] = useState(null)

    let history = useHistory();

    useEffect(() => {
        API.get(`/acl/permissions`).then((response) => {
            setPermissionTree(response.data.data);
        });
        if (props.match.params.id) {
            API.get(`/acl/role/${props.match.params.id}`).then((response) => {
                setRole(response.data.data);
            });
        }
    }, []);

    const handleChange = (e) => {
        setRole({ ...role, [e.target.name]: e.target.value })
        setError(null)
    };

    const handleCheckboxChange = (e) => {
        const currentPermissions = role.permissions;

        if (e.target.checked && !currentPermissions.includes(e.target.value)) {
            currentPermissions.push(e.target.value);
        }else {
            currentPermissions.splice(currentPermissions.indexOf(e.target.value), 1);
        }

        setRole({ ...role, ["permissions"]: currentPermissions })
    };

    const submit = () => {
        if (props.match.params.id) {
            API.put(`/acl/${props.match.params.id}`,role)
                .then((response) => {
                    toastr.success('Success', 'Role was successfully saved')
                    history.push("/user/aclroles")
                })
                .catch(error => {
                    setError('Role data')
                })
        }else {
            API.post('/acl',role)
                .then((response) => {
                    toastr.success('Success', 'Role was successfully saved')
                    history.push("/user/aclroles")
                })
                .catch(error => {
                    setError('Role data')
                })

        }
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">{props.match.params.id?'Edit':'New'} access role</h1>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Title</Label>
                            <AvInput onChange={handleChange} required type="text" name="title" value={role.title} placeholder="Title" />
                        </FormGroup>
                        {permissionTree &&
                        <>
                            Permissions
                            {permissionTree.map((permission,index) => {
                                if (permission.category) {
                                    return <div key={index} className="mt-2">
                                        <Folder/> {permission.category}
                                        <div className={"ml-3 mb-2"}>
                                            {permission.items.map((item, itemIndex) => {
                                                return <div key={itemIndex} className={"m-1"}>
                                                    <label><input type="checkbox" onChange={handleCheckboxChange} value={item.id} checked={role.permissions.includes(item.id)}/> {item.name}</label>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                }else {
                                    return <label>
                                        <input type="checkbox" onChange={handleCheckboxChange} value={permission.id} checked={role.permissions.includes(permission.id)}/> {permission.name}
                                    </label>
                                }
                            })}
                        </>
                        }
                        <div className="text-center">
                        <Button color="primary">{props.match.params.id?'Save':'Create'}</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default Role;
