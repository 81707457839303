import React,{useEffect,useState} from "react";
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import API from "../../utils/API";
import AdminStatus from "../../components/status/AdminStatus";
import {Edit2, Trash} from "react-feather";
import Loader from "../../components/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const Source = () => {
    const [source,setSource] = useState(null)
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        API.get('/source').then((response) => {
            setSource(response.data.data)
            setLoading(false)
        });
    }, [])

    return (
        <Container fluid className="p-0">
            <Button color="primary" className="float-right mt-n1">
                <FontAwesomeIcon icon={faPlus}/> New source
            </Button>
            <h1 className="h3 mb-3">Source management</h1>
            <Row>
                <Col>
                    {loading &&
                        <Loader />
                    }
                    <Card>
                        {source &&
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Slug</th>
                                        <th>Title</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        source.map((sourceItem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{sourceItem.id}</td>
                                                    <td>{sourceItem.slug}</td>
                                                    <td>{sourceItem.title}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Source
