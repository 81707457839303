import React,{useEffect} from "react";
import "../utils/logrockets";
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import IdleTimeOut from "../components/IdleTimeOut";
import MFASetUpNotification from "../components/MFASetUpNotification";
import {useDispatch,useSelector} from "react-redux";
import LogRocket from 'logrocket';
import {login, logout} from "../redux/actions/userActions";
import API from "../utils/API";

const Dashboard = ({ children }) => {

    const dispatch = useDispatch();

    const user = useSelector(state => {
        return state.user.user
    });

    useEffect(() => {
        if (user) {
            LogRocket.identify(user.email, {
                name: user.name,
                email: user.email,
            });
        }
    }, [user])

    useEffect(() => {
        const interval = setInterval(() => {
            if (!localStorage.getItem("jwt")) {
                dispatch(logout());
            }
        }, 5000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            API.get("/me").then(response => {
                dispatch(login(response.data.data));
            })
        }, 10000);

        return () => clearInterval(interval);
    }, [])

    return <React.Fragment>
        <IdleTimeOut/>
        <MFASetUpNotification/>
        <Wrapper>
            <Sidebar/>
            <Main>
                <Navbar/>
                <Content>{children}</Content>
                <Footer/>
            </Main>
        </Wrapper>
    </React.Fragment>
};

export default Dashboard;
